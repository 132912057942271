import React from "react";
import styled from "styled-components";
import { LustriaP, OpenSansP } from "../../text";

// Nested component imports

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 24px;
  padding: 0px 0px 29px;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const HeaderContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.steelBlue};
  margin-bottom: 20px;
  border-radius: 24px 24px 0 0;
  padding: 20px 12px 20px 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 660px;
  border-bottom: 1px solid ${(props) => props.theme.colors.steelBlue};

  @media only screen and (max-width: 800px) {
    width: 322px;
  }
`;
export const FaqTitle = styled(LustriaP)`
  max-width: 596px;
  font-size: 25px;
  line-height: 32px;
  color: ${(props) => props.theme.textColors.white};

  @media only screen and (max-width: 800px) {
    max-width: 258px;
  }
`;
export const ButtonIcon = styled.img`
  width: 48px;
  height: 48px;
  cursor: pointer;
`;
export const FaqParagraph = styled(OpenSansP)`
  max-width: 640px;
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  padding-left: 22px;
  @media only screen and (max-width: 800px) {
    max-width: 302px;
  }
`;

const AccordionDesktopOpenComponent = ({
  className,
  answer,
  question,
  close,
}) => {
  return (
    <Container className={className}>
      <HeaderContainer>
        <FaqTitle>{question}</FaqTitle>
        <ButtonIcon
          onClick={close}
          alt="buttonIcon"
          src="https://static.overlay-tech.com/assets/838ecabc-8b49-4216-ab81-585cf467f790.svg"
        />
      </HeaderContainer>
      <FaqParagraph>{answer}</FaqParagraph>
    </Container>
  );
};

export default AccordionDesktopOpenComponent;
