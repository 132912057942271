import React from "react";
import styled from "styled-components";
import { RichText, Link } from "prismic-reactjs";

const handleDestructure = ({ primary, fields = [] }) => {
  const title = RichText.asText(primary?.title);
  const clientLogos = [];
  fields.forEach((x) => {
    clientLogos.push({
      logoUrl: Link.url(x.logo),
      alt: x.logo.alt,
      clientWebsite: RichText.asText(x.client_website),
    });
  });

  return {
    title,
    employees: clientLogos,
  };
};

export default (slice) => {
  const ourTeam = handleDestructure(slice);

  if (!ourTeam) return null;
  const contentTitle = ourTeam?.title;
  return (
    <ClientLogosWrapper aria-label={contentTitle}>
      <Title>{contentTitle}</Title>
      <ClientLogoWrapper>
        {ourTeam.employees?.map((t) => (
          <ClientLogo target="_blank" href={t.clientWebsite || null}>
            <img src={t.logoUrl} alt={t.alt || "client logo"}></img>
          </ClientLogo>
        ))}
      </ClientLogoWrapper>
    </ClientLogosWrapper>
  );
};

const Title = styled.h2`
  font-family: ${({ theme }) =>
    theme.fonts.desktop1HeadingXxlBlackDefaultCentre.family};
  font-size: ${({ theme }) =>
    theme.fonts.desktop1HeadingXxlBlackDefaultCentre.size};
  line-height: ${({ theme }) =>
    theme.fonts.desktop1HeadingXxlBlackDefaultCentre.height};
  color: ${({ theme }) => theme.textColors.grey};
  text-align: center;
  margin-bottom: 60px;
`;

const ClientLogosWrapper = styled.section`
  max-width: 1200px;
  margin: 0 auto;
`;

const ClientLogo = styled.a`
  width: 260px;
  height: 260px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  border: 1px solid ${({ theme }) => theme.colors.grey};
  box-sizing: border-box;
  border-radius: 24px;
  transition: all 0.3;

  :hover {
    box-shadow: 0px 4px 16px ${({ theme }) => theme.colors.boxShadow};
  }

  img {
    display: block;
    max-width: 205px;
    max-height: auto;
  }

  @media only screen and (min-width: 1200px) {
    max-width: 25%;
  }

  @media only screen and (max-width: 600px) {
    width: 170px;
    height: 170px;

    img {
      max-width: 135px;
    }
  }
`;

const ClientLogoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 20px;

  .modal-dialog {
    max-width: 700px;
  }
  .modal-body {
    padding-left: 35px;
    padding-right: 50px;
  }
  .modal-content {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    border: none;
  }
`;
