import React from "react";
import styled from "styled-components";
import { CallToActionWrapper } from "./case-study/CallToAction";
import { RichText, Elements } from "prismic-reactjs";
import { routeHelper } from "../utils/routeHelper";
import {
  LustriaH2,
  LustriaH3,
  LustriaH4,
  LustriaH5,
  LustriaH6,
} from "../components/text";

let ELEMENTS_DISPLAYED = 0;

export const htmlSerializer = (isCaseStudy = true) => (
  type,
  element,
  _,
  children
) => {
  let renderItem = [];

  switch (type) {
    case Elements.heading2:
      if (!element.text) return null;

      ELEMENTS_DISPLAYED++;
      renderItem.push(<H2 isCaseStudy={isCaseStudy}>{children}</H2>);
      break;
    case Elements.heading3:
      if (!element.text) return null;

      ELEMENTS_DISPLAYED++;
      renderItem.push(<H3 isCaseStudy={isCaseStudy}>{children}</H3>);
      break;
    case Elements.heading5:
      if (!element.text) return null;

      ELEMENTS_DISPLAYED++;
      renderItem.push(<H5 isCaseStudy={isCaseStudy}>{children}</H5>);
      break;
    case Elements.heading4:
      if (!element.text) return null;

      ELEMENTS_DISPLAYED++;
      renderItem.push(<H4 isCaseStudy={isCaseStudy}>{children}</H4>);
      break;

    case Elements.heading6:
      if (!element.text) return null;
      ELEMENTS_DISPLAYED++;

      renderItem.push(<H6 isCaseStudy={isCaseStudy}>{children}</H6>);
      break;

    case Elements.paragraph:
      if (!element.text) return null;
      renderItem.push(<Paragraph>{children}</Paragraph>);
      break;

    case Elements.list:
      renderItem.push(<UL>{children}</UL>);
      break;

    case Elements.oList:
      renderItem.push(<OL>{children}</OL>);
      break;

    case Elements.image: // Image
      const linkUrl = element.url;
      const alt = element.alt;
      renderItem.push(
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Image src={linkUrl} alt={alt} />
          {alt && <ImageAlt>{alt}</ImageAlt>}
        </div>
      );
      break;

    case Elements.embed: {
      const { oembed } = element;

      const { embed_url } = oembed;

      if (embed_url.includes("youtube") && !embed_url.includes("embed")) {
        const split = embed_url.split("watch?v=");
        const url = [...split[0], "embed/", split[1]].join("");

        renderItem.push(
          <div className="d-flex justify-content-center">
            <EmbeddedVideo src={url} />
          </div>
        );
        break;
      }

      if (embed_url.includes("vimeo") && !embed_url.includes("player")) {
        const split = embed_url.split("/");
        const videoId = split[split.length - 1];

        const url = `https://player.vimeo.com/video/${videoId}`;

        renderItem.push(
          <div className="d-flex justify-content-center">
            <EmbeddedVideo src={url} />
          </div>
        );
        break;
      }

      renderItem.push(
        <div className="d-flex justify-content-center">
          <EmbeddedVideo src={embed_url} />
        </div>
      );
      break;
    }

    default:
      return null;
  }

  return renderItem;
};

export const renderContent = (content) => {
  return content
    .map((x) => {
      let actualContent = (
        <RichText render={x.content} htmlSerializer={htmlSerializer()} />
      );

      const smallCTA = x.small_call_to_action ? (
        <SmallCTA {...x.small_call_to_action} />
      ) : (
        <></>
      );

      return [actualContent, smallCTA].flatMap((x) => x);
    })
    .flatMap((x) => x);
};
const ContentRenderer = ({ content }) => <>{renderContent(content)}</>;

export default ContentRenderer;

const Paragraph = styled.p`
  letter-spacing: 0;
  line-height: 28px;
  opacity: 0.7;
  font-family: Helvetica;
  font-size: 1.25rem;
  margin-bottom: 20px !important;
  color: ${({ theme }) => theme.colors.navyPrimary};
`;

const SmallCTAButton = styled.a`
  margin-left: 24px;
  margin-bottom: 0 !important;
  color: ${({ theme }) => theme.textColors.white} !important;
  font-family: Agenda;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  text-decoration: none;
  padding: 8px 24px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.navyPrimary};

  &:hover {
    color: ${({ theme }) => theme.textColors.white};
  }
`;

const H2 = styled(LustriaH2)`
  color: ${({ theme, isCaseStudy }) =>
    isCaseStudy ? theme.textColors.grey : theme.textColors.steelBlue};
  margin-bottom: 24px !important;
  line-height: 37px;
`;

const H3 = styled(LustriaH3)`
  color: ${({ theme, isCaseStudy }) =>
    isCaseStudy ? theme.textColors.grey : theme.textColors.steelBlue};
  margin-bottom: 24px !important;
  line-height: 1.9rem;
`;

const H4 = styled(LustriaH4)`
  color: ${({ theme, isCaseStudy }) =>
    isCaseStudy ? theme.textColors.grey : theme.textColors.steelBlue};
  margin-bottom: 24px !important;
  line-height: 1.7rem;
`;

const H5 = styled(LustriaH5)`
  color: ${({ theme, isCaseStudy }) =>
    isCaseStudy ? theme.textColors.grey : theme.textColors.steelBlue};
  margin-bottom: 24px !important;
  line-height: 1.5rem;
`;

const H6 = styled(LustriaH6)`
  color: ${({ theme, isCaseStudy }) =>
    isCaseStudy ? theme.textColors.grey : theme.textColors.steelBlue};
  margin-bottom: 24px !important;
  line-height: 1.4rem;
`;

const Image = styled.img`
  width: 100%;
  @media (min-width: 992px) {
    width: 670px;
  }
  margin-bottom: 24px !important;
`;

const EmbeddedVideo = styled.iframe`
  border: 0;
  outline: 0;
  width: 100%;
  @media (min-width: 992px) {
    width: 670px;
    height: 401px;
  }

  height: 200px;
  margin: 0 auto 0 !important;
`;

const ImageAlt = styled.p`
  opacity: 0.7;
  margin-bottom: 0 !important;
`;

export const TriangleWrapper = styled.div`
  margin-bottom: 24px;
  text-align: center;
`;

const UL = styled.ul`
  list-style: auto !important;
  padding: 0 20px;

  li {
    margin-bottom: 8px !important;
  }
`;

const OL = styled.ol`
  list-style: auto !important;
  padding: 0 20px;

  li {
    margin-bottom: 8px !important;
  }
`;

const Triangle = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  position: relative;
  margin: 0 ${({ margin }) => margin} 0px !important;

  &::after {
    content: "";
    background-color: green;
    
     position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      clip-path: polygon(
        100% 0,
        0% 0%,
        100% 100%
      );
`;

const Quote = styled.span`
  color: ${({ theme }) => theme.colors.navyPrimary};
  font-family: Agenda !important;
  font-size: 39px;
  letter-spacing: 0;
  line-height: 48px;
  font-weight: 200;
  text-align: center;
  margin-bottom: 0 !important;

  @media (min-width: 992px) {
    width: 470px;
  }
`;

const SmallCTAWrapper = styled(CallToActionWrapper)`
  padding: 14px;
  font-family: Helvetica;
  font-size: 18px;
  color: #13a9ae;
`;

const SmallCTA = ({ title, link }) => (
  <SmallCTAWrapper className="d-flex justify-content-center align-items-center">
    {RichText.asText(title)}
    <SmallCTAButton href={routeHelper(link ? link[0].link_url.url : "#")}>
      {RichText.asText(link ? link[0].link_text : "")}
    </SmallCTAButton>
  </SmallCTAWrapper>
);

export const Triangles = ({ width, height, margin }) => (
  <TriangleWrapper className="d-flex justify-content-center">
    <Triangle width={width} height={height} margin={margin} />
    <Triangle width={width} height={height} margin={margin} />
    <Triangle width={width} height={height} margin={margin} />
  </TriangleWrapper>
);
