import React from "react";
import styled from "styled-components";
import { LustriaP, OpenSansP } from "../text";
import { RichText } from "prismic-reactjs";

const handleDestructure = ({ primary, fields = [] }) => {
  const title = RichText.asText(primary?.title);
  const employees = [];
  fields.forEach((x) => {
    employees.push({
      fullName: RichText.asText(x.full_name),
      jobTitle: RichText.asText(x.job_title),
      description: x.description ? x?.description?.map((d) => d.text) : [],
      avatar: x.avatar?.url,
      url_destination: RichText.asText(x.url_destination),
      url_display_text: RichText.asText(x.url_display_text),
      url_icon: x.url_icon?.url,
    });
  });

  return {
    title,
    employees,
  };
};

export default (slice) => {
  const ourTeam = handleDestructure(slice);

  if (!ourTeam) return null;
  const contentTitle = ourTeam?.title;
  return (
    <OurTeamWrapper aria-label={contentTitle}>
      <Title>{contentTitle}</Title>
      <EmployeesWrapper>
        {ourTeam.employees?.map((t, index) => (
          <>
            <Employee
              data-bs-toggle="modal"
              data-bs-target={"#description_" + index}
            >
              <img src={t.avatar} alt={`Employee ${t.fullName}`}></img>
              <FullName>{t.fullName}</FullName>
              <JobTitle>{t.jobTitle}</JobTitle>
            </Employee>
            <div
              class="modal fade"
              id={"description_" + index}
              tabindex="-1"
              aria-labelledby={"description_" + index}
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <ModalHeader>
                    <div>
                      <FullNameModal>{t.fullName}</FullNameModal>
                      <JobTitleModal>{t.jobTitle}</JobTitleModal>
                    </div>
                    <button
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    />
                  </ModalHeader>
                  <div class="modal-body">
                    <Description>{t.description}</Description>
                    {t.url_destination && (
                      <UrlWrapper>
                        {t.url_icon && (
                          <Icon src={t.url_icon} alt="icon"></Icon>
                        )}
                        <URL target="_blank" href={t.url_destination}>
                          {t.url_display_text}
                        </URL>
                      </UrlWrapper>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
      </EmployeesWrapper>
    </OurTeamWrapper>
  );
};

const Title = styled.h2`
  font-family: ${({ theme }) =>
    theme.fonts.desktop1HeadingXxlBlackDefaultCentre.family};
  font-size: ${({ theme }) =>
    theme.fonts.desktop1HeadingXxlBlackDefaultCentre.size};
  line-height: ${({ theme }) =>
    theme.fonts.desktop1HeadingXxlBlackDefaultCentre.height};
  color: ${({ theme }) => theme.textColors.grey};
  text-align: center;
  margin-bottom: 60px;
`;

const OurTeamWrapper = styled.section`
  max-width: 1200px;
  margin: 0 auto;
`;

const Employee = styled.div`
  margin-bottom: 40px;
  cursor: pointer;

  img {
    display: block;
    max-width: 170px;
    height: auto;

    @media only screen and (min-width: 600px) {
      max-width: 260px !important;
    }

    @media only screen and (min-width: 450px) {
      max-width: 180px;
    }
  }

  @media only screen and (min-width: 1200px) {
    max-width: 25%;
    margin-bottom: 80px;
  }
`;

const EmployeesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 20px;

  .modal-dialog {
    max-width: 700px;
  }
  .modal-body {
    padding-left: 35px;
    padding-right: 50px;
  }
  .modal-content {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    border: none;
  }
`;

const JobTitle = styled(LustriaP)`
  font-size: 25px;
  line-height: 32px;

  @media only screen and (max-width: 600px) {
    font-size: 15px;
  }
  @media only screen and (min-width: 600px) {
    max-width: 260px !important;
  }

  @media only screen and (min-width: 450px) {
    max-width: 180px;
  }
`;

const FullName = styled(JobTitle)`
  margin-top: 20px;
  color: ${({ theme }) => theme.textColors.steelBlue};
`;

const Description = styled(OpenSansP)`
  margin-top: 20px;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 80px;
  color: ${({ theme }) => theme.colors.grey};
  opacity: 0.7;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-left: 35px;
  padding-right: 20px;

  button {
    background-color: ${({ theme }) => theme.colors.greyOpacity_01};
    padding: 8px;
    border-radius: 8px;
  }
`;

const FullNameModal = styled(FullName)`
  font-size: 39px;
  line-height: 48px;
`;

const JobTitleModal = styled(JobTitle)`
  font-size: 39px;
  line-height: 48px;
  color: ${({ theme }) => theme.colors.grey};
`;

const UrlWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  width: 45px;
  height: 45px;
`;

const URL = styled.a`
  font-family: OpenSans;
  color: ${({ theme }) => theme.textColors.grey};
  margin-left: 5px;
  font-size: 13px;
  line-height: 18px;

  :hover {
    color: ${({ theme }) => theme.textColors.grey};
  }
`;
