import React from "react";
import styled from "styled-components";
import { Themes } from "../models/themes";
import { routeHelper } from "../../utils/routeHelper";
import { OpenSansP } from "../text";
import { RichText } from "prismic-reactjs";

const handleDestructure = ({ primary }) => {
  const title = RichText.asText(primary?.title);
  const description = RichText.asText(primary?.description);
  const heroImageUrl = primary?.hero_image?.url;
  const displayCta = primary?.display_cta;
  const callToActionLabel = RichText.asText(primary?.call_to_action_label);
  const backgroundColor = primary?.background_color;
  const callToActionLink = primary?.call_to_action_link?._meta?.uid
    ? primary?.call_to_action_link?._meta?.uid.replace(/__/g, "/")
    : primary?.call_to_action_link?.url;
  const heroImageAltText = primary?.hero_image?.alt ?? "image";
  return {
    title,
    description,
    heroImageUrl,
    displayCta,
    callToActionLabel,
    backgroundColor,
    callToActionLink,
    heroImageAltText
  };
};

const handleHeroLink = (item) => {
  if (item?.includes(".")) {
    return item;
  }
  if (item?.includes("https://")) return item.split("https://")[1];
  return `/${item}`;
};

const HeroImage = (primary) => {
  const heroImage = handleDestructure(primary);

  if (!heroImage) return null;
  const contentTitle = heroImage.title || heroImage.description;
  if (heroImage.backgroundColor === Themes.image) {
    return (
      <CallToActionLink
        target="_blank"
        backgroundImage={heroImage.heroImageUrl}
        aria-label={contentTitle}
      >
        <ImagedHeroWrapper className="d-flex ">
          <HeroContentWrapper className="d-flex ">
            {contentTitle && (
              <HeroImagedTextWrapper colorMode={heroImage?.backgroundColor}>
                <HeroTitle colorMode={heroImage?.backgroundColor}>
                  {heroImage?.title}
                </HeroTitle>
                <HeroDescription colorMode={heroImage?.backgroundColor}>
                  {heroImage?.description}
                </HeroDescription>
                {heroImage.displayCta && heroImage.callToActionLink && (
                  <ButtonWrapper className="d-flex">
                    <ButtonCTA
                      href={`${routeHelper(
                        handleHeroLink(heroImage?.callToActionLink)
                      )}`}
                      className="d-flex flex-row align-items-center justify-content-space-between"
                      colorMode={heroImage.backgroundColor}
                    >
                      <Text colorMode={heroImage.backgroundColor}>
                        {heroImage.callToActionLabel}{" "}
                      </Text>
                    </ButtonCTA>
                  </ButtonWrapper>
                )}
              </HeroImagedTextWrapper>
            )}
          </HeroContentWrapper>
        </ImagedHeroWrapper>
      </CallToActionLink>
    );
  }
  return (
    <CallToActionLink
      target="_blank"
      colorMode={heroImage?.backgroundColor}
      aria-label={contentTitle}
    >
      <CallToActionWrapper className="d-flex ">
        <ImageWrapper className="d-flex ">
          <CTAImage src={heroImage.heroImageUrl} className="align-items-ends" alt={heroImage.heroImageAltText} />
        </ImageWrapper>
        <ContentWrapper className="d-flex ">
          <TextWrapper colorMode={heroImage?.backgroundColor}>
            <HeroTitle>{heroImage?.title}</HeroTitle>
            <HeroDescription>{heroImage?.description}</HeroDescription>
            {heroImage.displayCta && (
              <ButtonWrapper className="d-flex">
                <ButtonCTA
                  href={`${routeHelper(
                    handleHeroLink(heroImage?.callToActionLink)
                  )}`}
                  className="d-flex flex-row align-items-center justify-content-space-between"
                  colorMode={heroImage.backgroundColor}
                >
                  <Text colorMode={heroImage.backgroundColor}>
                    {heroImage.callToActionLabel}{" "}
                  </Text>
                </ButtonCTA>
              </ButtonWrapper>
            )}
          </TextWrapper>
        </ContentWrapper>
      </CallToActionWrapper>
    </CallToActionLink>
  );
};

const ButtonWrapper = styled.div`
  width: auto;
  margin-top: 24px;
`;
const TextWrapper = styled.div`
  color: ${({ colorMode, theme }) => {
    switch (colorMode) {
      case Themes.blue:
      case Themes.green:
      case Themes.image:
        return "white";
      case Themes.lightGreen:
      case Themes.white:
        return theme.colors.navyPrimary;
      default:
        return theme.colors.navyPrimary;
    }
  }};

  @media only screen and (max-width: 1200px) {
    padding: 0;
    margin: 0;
  }
`;

const ContentWrapper = styled.div`
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1200px) {
    padding-left: 0;
    margin: 0 auto;
    max-width: 450px;
  }
`;

const HeroContentWrapper = styled(ContentWrapper)`
  background-color: ${({ theme }) => theme.colors.whiteOpacity_02};
  padding-left: 0;
  @media only screen and (min-width: 800px) {
    padding: 0;
    margin: 0 0 0 auto;
  }
`;

const HeroImagedTextWrapper = styled(TextWrapper)`
  padding: 38px 33px 44px 33px;
`;
const ImageWrapper = styled.div`
  justify-content: center;
  align-items: center;
  padding: 0 0 0 128px;
  @media only screen and (max-width: 1200px) {
    padding: 0;
    margin: 0 auto;
  }
`;

export const CallToActionWrapper = styled.div`
  max-width: 1800px;
  margin: 0 auto;
  justify-content: space-between;
  padding: 80px 48px;

  flex-direction: row-reverse;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    padding: 112px 24px;
  }

  padding-bottom: ${({ colorMode, theme }) =>
    colorMode === Themes.white ? "0px" : "112px"};
  font-family: Agenda;
  font-size: 31px;

  div {
    max-width: 800px;
  }
`;

const ImagedHeroWrapper = styled(CallToActionWrapper)`
  justify-content: flex-start;
  margin: auto;
`;

const CallToActionLink = styled.section`
  font-family: Agenda-Semibold;
  min-height: 624px;
  background-color: ${({ colorMode, theme }) => {
    switch (colorMode) {
      case Themes.blue:
        return theme.colors.navyPrimary;
      case Themes.green:
        return theme.colors.green;
      case Themes.lightGreen:
        return theme.colors.greenBackground;
      case Themes.white:
        return "white";
      default:
        return "";
    }
  }};
  background-image: url(${({ backgroundImage }) => {
    return backgroundImage;
  }});
  background-size: cover;
  background-position: center;
  box-shadow: inset 0 0 0 1000px ${({ theme }) => theme.colors.navyTransparent};
  @media (max-width: 380px) {
    width: 380px;
  }
`;

const CTAImage = styled.img`
  min-height: 400px;
  max-width: 550px;
  width: auto;
  height: auto;
  height: 100%;
  object-fit: cover;

  @media only screen and (max-width: 1200px) {
    padding: 0 0 32px 0;
    max-width: 450px;
  }
`;

const HeroDescription = styled(OpenSansP)`
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
  color: ${({ colorMode, theme }) =>
    colorMode === Themes.image ? theme.textColors.steelBlue : "inherited"};
  margin-top: 16px;
`;

const HeroTitle = styled.h1`
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0px;
  text-align: left;
  font-family: Lustria;
  color: ${({ colorMode, theme }) =>
    colorMode === Themes.image ? theme.textColors.grey : "inherited"};

  @media only screen and (max-width: 1200px) {
    max-width: 362px;
    margin: 0;
  }
`;

export const ButtonCTA = styled.a`
  transition: all 0.3s;
  background-color: white;
  width: auto;
  display: flex;
  border-radius: 4px;
  border-sizing: border-box;
  padding: 10px 35px 11px 35px;
  border: 1px solid ${(props) => props.theme.colors.steelBlue};
  text-decoration: none;
  cursor: pointer;
  background-color: ${({ colorMode, theme }) => {
    switch (colorMode) {
      case Themes.darkGreen:
      case Themes.image:
      case Themes.green:
      case Themes.blue:
        return theme.colors.white;
      case Themes.lightGreen:
      case Themes.white:
        return theme.colors.navyPrimary;
      default:
        return "";
    }
  }};

  border: ${({ colorMode, theme }) => {
    switch (colorMode) {
      case Themes.blue:
        return "1px solid " + theme.colors.steelBlue;
      default:
        return "";
    }
  }};

  :hover {
    background-color: ${({ colorMode, theme }) => {
      switch (colorMode) {
        case Themes.image:
        case Themes.blue:
          return theme.colors.lightButtonHover;
        case Themes.darkGreen:
        case Themes.green:
          return theme.colors.navyBackgroundPressed;
        case Themes.lightGreen:
        case Themes.white:
          return theme.colors.navyBackgroundHover;
        default:
          return "";
      }
    }};
  }
  :active {
    background-color: ${({ colorMode, theme }) => {
      switch (colorMode) {
        case Themes.lightGreen:
        case Themes.white:
          return theme.colors.navyBackgroundPressed;
        case Themes.darkGreen:
        case Themes.image:
        case Themes.green:
        case Themes.blue:
          return theme.colors.steelBlueOpacity_01;
        default:
          return "";
      }
    }};
    color: ${({ colorMode, theme }) => {
      switch (colorMode) {
        case Themes.blue:
          return theme.textColors.steelBlue;
        default:
          return "inherit";
      }
    }} !important;
  }
`;

export const Text = styled(OpenSansP)`
  font-family: Raleway-Semibold;
  font-size: ${(props) => props.theme.fonts.buttonLeftWhite.size};
  line-height: ${(props) => props.theme.fonts.buttonLeftWhite.lineHeight};
  color: ${({ colorMode, theme }) => {
    switch (colorMode) {
      case Themes.lightGreen:
      case Themes.white:
        return "white";
      case Themes.darkGreen:
      case Themes.green:
      case Themes.image:
      case Themes.blue:
        return theme.colors.steelBlue;
      default:
        return "";
    }
  }};
`;

export default HeroImage;
