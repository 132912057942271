import React, { useState } from "react";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";
import AccordionDesktopOpen from "./components/AccordionDesktopOpenComponent";
import AccordionDesktopClosed from "./components/AccordionDesktopClosedComponent";
import { LustriaP } from "../text";

const handleFAQDestructure = ({ fields }) => {
  let faqs = [];
  if (!fields) return null;
  fields.forEach((faq) => {
    faqs.push({
      answer: RichText.asText(faq?.answer),
      question: RichText.asText(faq?.question),
    });
  });
  return faqs;
};

const FAQDesktopComponent = (primary) => {
  const faqs = handleFAQDestructure(primary);
  const [activeFaq, setActiveFaq] = useState(-1);
  if (!faqs) return null;
  const contentTitle = "Frequently Asked Questions";

  return (
    <FaqDesktop aria-label={contentTitle}>
      <FaqContents>
        <FrequentlyAskedQue>{contentTitle}</FrequentlyAskedQue>
        {faqs.map((faq, index) => {
          if (activeFaq === index)
            return (
              <AccordionDesktopOpenItem
                question={faq.question}
                answer={faq.answer}
                close={() => setActiveFaq(-1)}
              />
            );
          else
            return (
              <AccordionDesktopClosedItem
                question={faq.question}
                answer={faq.answer}
                open={() => setActiveFaq(index)}
              />
            );
        })}
      </FaqContents>
    </FaqDesktop>
  );
};

export const FaqDesktop = styled.section`
  padding: 0px 0px 96px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 800px) {
    padding: 0px 0px 63px;
  }
`;

export const FaqContents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 119px;
`;

export const FrequentlyAskedQue = styled(LustriaP)`
  width: 690px;
  font-size: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.size};
  line-height: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.lineHeight};
  text-align: center;
  margin-bottom: 48px;
  @media only screen and (max-width: 800px) {
    max-width: 366px;
    margin-bottom: 16px;
  }
`;

export const FrequentlyAskedQueEmphasis2 = styled.strong`
  font-family: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.family};
  font-size: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.size};
  font-weight: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.weight};
  line-height: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.lineHeight};
  color: ${(props) => props.theme.textColors.greenPrimary};
`;

export const AccordionDesktopOpenItem = styled(AccordionDesktopOpen)`
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

export const AccordionDesktopClosedItem = styled(AccordionDesktopClosed)`
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`;

export default FAQDesktopComponent;
