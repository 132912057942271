import React from "react";
import Quote from "./slices/Quote";
import styled from "styled-components";
import HeroImage from "./slices/HeroImage";
import ImageTextPanelDesktopComponent from "./layouts/ImageTextPanelDesktopComponent";
import VideoPanelDesktopComponent from "./layouts/VideoPanelDesktopComponent";
import FAQDesktopComponent from "./layouts/FAQDesktopComponent";
import CallToActionH2Component from "./layouts/components/CallToActionH2";
import PullQuoteComponent from "./layouts/PullQuoteComponent";
import RichTextComponent from "./slices/Text";
import { Themes } from "./models/themes";
import CallToAction from "../templates/case-study/CallToAction";
import ImagePanel from "./layouts/ImagePanel";
import FormPanelDesktopComponent from "./layouts/FormPanelDesktopComponent";
import RelatedPage from "./layouts/RelatedPage";
import { sliceComponents } from "./models/prismicTypes";
import LandingPage from "./slices/LandingPage";
import OurTeam from "./slices/OurTeam";
import ClientLogos from "./slices/ClientLogos";
import CardFormComponent from "./slices/CardFormComponent";

const Slice = ({ slice, caseStudy }) => {
  switch (slice.type) {
    case sliceComponents.richText:
      return (
        <SliceWrapper>
          <RichTextComponent {...slice} isCaseStudy={caseStudy} />
        </SliceWrapper>
      );
    case sliceComponents.quote:
      return (
        <SliceWrapper>
          <Quote {...slice} />
        </SliceWrapper>
      );
    case sliceComponents.heroImage:
      return (
        <SliceWrapper>
          <HeroImage {...slice} />
        </SliceWrapper>
      );
    case sliceComponents.videoPanel:
      return (
        <SliceWrapper>
          <VideoPanelDesktopComponent {...slice} />
        </SliceWrapper>
      );
    case sliceComponents.cardPanel:
      return (
        <SliceWrapper>
          <ImageTextPanelDesktopComponent {...slice} />
        </SliceWrapper>
      );
    case sliceComponents.callToAction:
      return (
        <SliceWrapper>
          {slice.primary.cta_type === Themes.h2Cta ? (
            <CallToActionH2Component {...slice} />
          ) : (
            <CallToAction {...slice} />
          )}
        </SliceWrapper>
      );
    case sliceComponents.pullQuote:
      return (
        <SliceWrapper>
          <PullQuoteComponent {...slice} isCaseStudy={caseStudy} />
        </SliceWrapper>
      );
    case sliceComponents.frequentlyAskedQuestion:
      return (
        <SliceWrapper>
          <FAQDesktopComponent {...slice} />
        </SliceWrapper>
      );
    case sliceComponents.imagePanel:
      return (
        <SliceWrapper>
          <ImagePanel {...slice} />
        </SliceWrapper>
      );
    case sliceComponents.eoi:
      return slice.primary.show_eoi_form ? (
        <SliceWrapper>
          <FormPanelDesktopComponent />
        </SliceWrapper>
      ) : (
        <></>
      );
    case sliceComponents.relatedPage:
      return (
        <SliceWrapper>
          <RelatedPage {...slice} />
        </SliceWrapper>
      );
    case sliceComponents.landingPage:
      return (
        <SliceWrapper>
          <LandingPage {...slice}></LandingPage>
        </SliceWrapper>
      );
    case sliceComponents.ourTeam:
      return (
        <SliceWrapper>
          <OurTeam {...slice}></OurTeam>
        </SliceWrapper>
      );

    case sliceComponents.clientLogos:
      return (
        <SliceWrapper>
          <ClientLogos {...slice}></ClientLogos>
        </SliceWrapper>
      );
      case sliceComponents.cardForm:
      return (
        <SliceWrapper>
          <CardFormComponent {...slice}></CardFormComponent>
        </SliceWrapper>
      );
    default:
      return <></>;
  }
};

const SliceWrapper = styled.div`
  width: 100%;
  justify-content: center;
  margin-bottom: 120px;

  @media only screen and (max-width: 1200px) {
    margin-bottom: 90px;
  }
`;
export default Slice;
