import React from "react";
import styled from "styled-components";
import CTAButton from "./CTAButton";
import SkildareLogo from "../../icons/SkildareLogo";
import { Themes } from "../../models/themes";
import { routeHelper } from "../../../utils/routeHelper";
import { RichText } from "prismic-reactjs";

const handleCTADestructure = ({ primary }) => {
  return {
    image: primary?.image?.url,
    title: RichText.asText(primary?.title1),
    subtitle: RichText.asText(primary?.subtitle),
    link: primary?.link?._meta?.uid
      ? primary?.link?._meta?.uid.replace(/__/g, "/")
      : primary?.link?.url,
    linkText: RichText.asText(primary?.link_text),
    theme: primary?.fixed_color,
  };
};

export const handleCTALink = (item) => {
  if (item) {
    if (item.includes(".")) {
      return item;
    }
    if (item.includes("https://")) return item.split("https://")[1];
    return `/${item}`;
  }
  return "";
};

const CallToActionH2Component = (primary) => {
  const cta = handleCTADestructure({ ...primary });

  if (!cta) return null;

  const contentTitle = cta.title;
  return (
    <CallToActionLink aria-label={contentTitle}>
      <CallToActionWrapper
        className="d-flex justify-content-center"
        colorMode={cta.theme}
        image={cta.image ? cta.image : ""}
      >
        <div class="d-flex align-items-center">
          <TextWrapper>
            <CTATitle colorMode={cta.theme}>{contentTitle}</CTATitle>
            <CTASubtitle colorMode={cta.theme}>{cta.subtitle}</CTASubtitle>
            {cta.link && (
              <CTAButton
                theme={cta.theme}
                href={`${routeHelper(handleCTALink(cta.link))}`}
                title={cta.linkText || "Request a demo"}
              />
            )}
          </TextWrapper>
        </div>
        {cta.theme !== Themes.image && (
          <IconWrapper>
            <SkildareLogo />
          </IconWrapper>
        )}
      </CallToActionWrapper>
    </CallToActionLink>
  );
};

const TextWrapper = styled.div`
  padding: 0 24px;
`;

const IconWrapper = styled.div`
  right: 0;
  bottom: 0;
  margin: 0 80px 80px 0;
  position: absolute;
  @media only screen and (max-width: 1200px) {
    margin-bottom: -41px;
  }
`;

const CallToActionWrapper = styled.div`
  ${({ image }) =>
    image
      ? `background-image: url(${image});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;`
      : ``}
  position: relative;
  padding: 96px 0;
  background-color: ${({ colorMode, theme }) => {
    switch (colorMode) {
      case Themes.darkGrey:
        return theme.colors.grey;
      case Themes.image:
        return theme.colors.white;
      case Themes.lightGrey:
        return `rgba(66, 66, 66, 0.1)`;
      default:
        return theme.colors.lightGreenBackground;
    }
  }};
  font-family: Agenda;
  font-size: 31px;
  color: ${({ theme }) => theme.colors.navyPrimary};
  cursor: default;
  ${({ theme, colorMode }) =>
    colorMode === Themes.image
      ? `box-shadow: inset 0 0 0 1000px ${theme.colors.navyTransparent};`
      : ""}
  div {
    max-width: 1000px;
  }
`;

const CallToActionLink = styled.section`
  text-decoration: none;
`;

const CTATitle = styled.h2`
  font-size: 39px;
  font-family: Lustria;
  padding-bottom: 24px;
  text-align: center;
  color: ${({ colorMode, theme }) => {
    //The theme in the case needs to be contrasting to the value for display
    switch (colorMode) {
      case Themes.lightGrey:
        return theme.colors.grey;
      case Themes.darkGrey:
      case Themes.image:
        return "white";
      default:
        return "";
    }
  }};
`;

const CTASubtitle = styled(CTATitle)`
  text-align: center;
  font-size: 25px;
  padding-bottom: 0;
  font-weight: regular;
  font-family: "Agenda";
`;

export default CallToActionH2Component;
