import React from "react";

const SkildareLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="71"
    height="73"
    viewBox="0 0 71 73"
    fill="none"
  >
    <path
      d="M54.6274 4.607L53.838 3.18213L41.8389 4.81281C39.1824 11.5117 35.4047 17.7065 30.6687 23.1303C30.6402 21.5744 30.1595 20.0608 29.2856 18.7749C28.4117 17.489 27.1826 16.4868 25.749 15.8911C24.3154 15.2954 22.7396 15.1322 21.2149 15.4215C19.6901 15.7107 18.2827 16.4399 17.1651 17.5195C16.0476 18.5991 15.2685 19.9823 14.9235 21.4994C14.5784 23.0166 14.6824 24.6017 15.2226 26.0604C15.7628 27.5192 16.7158 28.7882 17.9647 29.7118C19.2136 30.6353 20.7041 31.1734 22.2535 31.2599C16.6737 35.8103 10.356 39.3661 3.576 41.7723L1.51562 53.7174L2.30504 55.1502L2.61291 53.3849C14.3493 49.8061 25.0639 43.4692 33.8658 34.9013C42.5893 26.5329 49.235 16.2304 53.2696 4.82073L54.6274 4.607Z"
      fill="#CDC717"
    />
    <path
      d="M70.1868 64.1188C60.9077 55.4812 49.6426 49.2783 37.3945 46.0626C25.1766 42.7495 12.3322 42.5103 0 45.366L0.789414 46.7988L1.09729 46.7038L7.59416 54.8018C14.6836 53.6058 21.9254 53.6406 29.0031 54.9047C27.6938 55.7355 26.6546 56.9311 26.0128 58.3451C25.3709 59.7592 25.1542 61.3302 25.3893 62.8659C25.6244 64.4015 26.301 65.8351 27.3363 66.991C28.3715 68.1469 29.7205 68.9749 31.218 69.3737C32.7154 69.7724 34.2964 69.7246 35.7671 69.236C37.2379 68.7474 38.5346 67.8393 39.4985 66.6229C40.4623 65.4065 41.0515 63.9346 41.1938 62.3876C41.3361 60.8405 41.0253 59.2854 40.2996 57.9127C47.0687 60.3437 53.3706 63.9237 58.9298 68.4963L69.6579 64.293L70.9999 65.512L70.1868 64.1188Z"
      fill="#0088A4"
    />
    <path
      d="M68.4659 59.4724C63.9935 53.8199 60.5223 47.4393 58.2035 40.6087C59.5606 41.3583 61.1062 41.6947 62.651 41.5768C64.1959 41.4589 65.6729 40.8918 66.9012 39.9449C68.1295 38.998 69.0557 37.7125 69.5665 36.2457C70.0772 34.779 70.1503 33.1947 69.7768 31.687C69.4032 30.1792 68.5993 28.8134 67.4634 27.7569C66.3275 26.7003 64.9089 25.9989 63.3815 25.7385C61.8541 25.4781 60.2842 25.6701 58.8639 26.2908C57.4436 26.9116 56.2347 27.9343 55.3853 29.2335C54.2415 22.1159 54.3269 14.8541 55.6379 7.76553L46.3781 0C43.3283 12.3182 43.3554 25.2005 46.457 37.5057C49.4567 49.8345 55.4407 61.2318 63.8794 70.6892L68.3633 59.7257L69.2711 60.9052L68.4659 59.4724Z"
      fill="#A9B533"
    />
    <path
      d="M42.6283 6.23761C39.9803 12.9324 36.2075 19.1226 31.4739 24.5392C31.4435 22.5275 30.6504 20.6028 29.2556 19.1564C28.3542 18.2206 27.2387 17.5196 26.0058 17.1141C24.773 16.7086 23.4601 16.611 22.1811 16.8296C20.9021 17.0483 19.6956 17.5766 18.6664 18.3688C17.6371 19.1609 16.8162 20.193 16.2749 21.3754C15.7336 22.5578 15.4883 23.8548 15.5601 25.1539C15.632 26.4529 16.0189 27.7148 16.6874 28.8299C17.3558 29.945 18.2855 30.8797 19.3958 31.5528C20.5061 32.2259 21.7634 32.6172 23.0587 32.6926C17.4817 37.2429 11.1667 40.7987 4.38907 43.205L2.3208 55.1501C14.4888 51.6434 25.606 45.1804 34.6868 36.334C43.8339 27.5631 50.6829 16.6657 54.6274 4.60693L42.6283 6.23761Z"
      fill="white"
    />
    <path
      d="M64.795 42.8097C66.0559 42.4974 67.2217 41.881 68.1909 41.014C69.1602 40.147 69.9035 39.0558 70.3563 37.8351C70.8091 36.6144 70.9577 35.3012 70.7891 34.0098C70.6205 32.7184 70.1398 31.4879 69.3889 30.4253C68.6379 29.3626 67.6395 28.5 66.4803 27.9124C65.3212 27.3249 64.0364 27.0302 62.7378 27.054C61.4392 27.0778 60.1661 27.4193 59.0291 28.0489C57.8921 28.6786 56.9258 29.5772 56.214 30.6666C55.0768 23.5456 55.1649 16.2819 56.4746 9.19071L47.191 1.43311C44.1374 13.7481 44.1645 26.6289 47.27 38.9309C50.2778 51.2671 56.2758 62.6681 64.7318 72.1223L69.3104 60.9055C64.843 55.2497 61.3722 48.87 59.048 42.0418C60.8002 43.0104 62.851 43.2844 64.795 42.8097Z"
      fill="white"
    />
    <path
      d="M71 65.5119C61.7139 56.8926 50.4502 50.7041 38.2078 47.4952C25.9823 44.1782 13.1293 43.9389 0.789551 46.7986L8.3995 56.2028C15.4915 55.0081 22.7351 55.0402 29.8163 56.2978C28.5009 57.122 27.4539 58.3131 26.8034 59.7251C26.153 61.137 25.9274 62.7086 26.1542 64.2473C26.3811 65.7859 27.0506 67.2247 28.0807 68.3876C29.1108 69.5504 30.4568 70.3867 31.9537 70.794C33.4507 71.2013 35.0336 71.1619 36.5086 70.6806C37.9835 70.1994 39.2865 69.2971 40.2578 68.0845C41.2291 66.8719 41.8266 65.4015 41.9771 63.8535C42.1275 62.3054 41.8244 60.747 41.1049 59.3691C47.8748 61.7929 54.1792 65.3648 59.743 69.929L71 65.5119Z"
      fill="white"
    />
    <g>
      <path
        d="M42.6283 6.23761C39.9803 12.9324 36.2075 19.1226 31.4739 24.5392C31.4435 22.5275 30.6504 20.6028 29.2556 19.1564C28.3542 18.2206 27.2387 17.5196 26.0058 17.1141C24.773 16.7086 23.4601 16.611 22.1811 16.8296C20.9021 17.0483 19.6956 17.5766 18.6664 18.3688C17.6371 19.1609 16.8162 20.193 16.2749 21.3754C15.7336 22.5578 15.4883 23.8548 15.5601 25.1539C15.632 26.4529 16.0189 27.7148 16.6874 28.8299C17.3558 29.945 18.2855 30.8797 19.3958 31.5528C20.5061 32.2259 21.7634 32.6172 23.0587 32.6926C17.4817 37.2429 11.1667 40.7987 4.38907 43.205L2.3208 55.1501C14.4888 51.6434 25.606 45.1804 34.6868 36.334C43.8339 27.5631 50.6829 16.6657 54.6274 4.60693L42.6283 6.23761Z"
        fill="#EBE838"
      />
    </g>
    <g>
      <path
        d="M64.795 42.8097C66.0559 42.4974 67.2217 41.881 68.1909 41.014C69.1602 40.147 69.9035 39.0558 70.3563 37.8351C70.8091 36.6144 70.9577 35.3012 70.7891 34.0098C70.6205 32.7184 70.1398 31.4879 69.3889 30.4253C68.6379 29.3626 67.6395 28.5 66.4803 27.9124C65.3212 27.3249 64.0364 27.0302 62.7378 27.054C61.4392 27.0778 60.1661 27.4193 59.0291 28.0489C57.8921 28.6786 56.9258 29.5772 56.214 30.6666C55.0768 23.5456 55.1649 16.2819 56.4746 9.19071L47.191 1.43311C44.1374 13.7481 44.1645 26.6289 47.27 38.9309C50.2778 51.2671 56.2758 62.6681 64.7318 72.1223L69.3104 60.9055C64.843 55.2497 61.3722 48.87 59.048 42.0418C60.8002 43.0104 62.851 43.2844 64.795 42.8097Z"
        fill="#C3D941"
      />
    </g>
    <g>
      <path
        d="M71 65.5119C61.7139 56.8926 50.4502 50.7041 38.2078 47.4952C25.9823 44.1782 13.1293 43.9389 0.789551 46.7986L8.3995 56.2028C15.4915 55.0081 22.7351 55.0402 29.8163 56.2978C28.5009 57.122 27.4539 58.3131 26.8034 59.7251C26.153 61.137 25.9274 62.7086 26.1542 64.2473C26.3811 65.7859 27.0506 67.2247 28.0807 68.3876C29.1108 69.5504 30.4568 70.3867 31.9537 70.794C33.4507 71.2013 35.0336 71.1619 36.5086 70.6806C37.9835 70.1994 39.2865 69.2971 40.2578 68.0845C41.2291 66.8719 41.8266 65.4015 41.9771 63.8535C42.1275 62.3054 41.8244 60.747 41.1049 59.3691C47.8748 61.7929 54.1792 65.3648 59.743 69.929L71 65.5119Z"
        fill="#00A4C4"
      />
    </g>
    <path
      d="M45.4308 4.32227L46.236 5.75505L44.9177 5.9292L45.4308 4.32227Z"
      fill="#CDC717"
    />
    <path
      d="M51.5013 5.03435L50.6882 3.60156L52.7407 4.86811L51.5013 5.03435Z"
      fill="#CDC717"
    />
    <path
      d="M3.0708 44.7097L3.8839 46.1425L4.21545 44.2031L3.0708 44.7097Z"
      fill="#CDC717"
    />
    <path
      d="M55.6616 7.76562L55.3301 8.23266L56.4747 9.19049L55.6616 7.76562Z"
      fill="#A9B533"
    />
  </svg>
);

export default SkildareLogo;
