import React from "react";
import styled from "styled-components";
import { Themes } from "../models/themes";
import { RichText } from "prismic-reactjs";

const handleVideoDestructure = (primary) => {
  return {
    videoTitleEmphasis: RichText.asText(primary?.video_title_emphasis),
    videoLink: primary.video_link?.url,
    theme: primary.theme,
    videoTitle: RichText.asText(primary?.video_title),
  };
};

const VideoPanelDesktopComponent = ({ primary }) => {
  const panel = handleVideoDestructure(primary);

  if (!panel) return null;
  return (
    <VideoPanelWrapper aria-label={panel.videoTitle}>
      {panel.videoTitle && panel.videoTitleEmphasis && (
        <Title>
          {panel.videoTitle}
          <br />
          <TitleEmphasis2>{panel.videoTitleEmphasis}</TitleEmphasis2>
        </Title>
      )}
      <VideoPanelDesktop>
        <VideoContainer themeColor={panel.theme}>
          <VideoResponsive>
            <StyledIframe
              src={panel.videoLink}
              width="665"
              height="374"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen={true}
            />
          </VideoResponsive>
        </VideoContainer>
      </VideoPanelDesktop>
    </VideoPanelWrapper>
  );
};

export const VideoPanelDesktop = styled.div`
  justify-content: center;
  display: flex;
  align-items: flex-start;
  @media only screen and (max-width: 800px) {
    padding: 45px 85px;
  }

  @media only screen and (max-width: 600px) {
    padding: 0;
    margin-bottom: 60px;
  }
`;

export const Title = styled.h2`
  font-family: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.family};
  font-size: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.size};
  font-weight: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.weight};
  line-height: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.lineHeight};
  color: ${(props) => props.theme.textColors.grey};
  margin-bottom: 16px;
  margin-left: 10%;
`;

export const TitleEmphasis2 = styled.strong`
  font-family: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.family};
  font-size: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.size};
  font-weight: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.weight};
  line-height: ${(props) =>
    props.theme.fonts.desktop1HeadingXxlBlackDefaultCentre.lineHeight};
  color: ${(props) => props.theme.textColors.steelBlue};
`;

const VideoContainer = styled.div`
  flex-grow: 1;
  padding: ${({ themeColor }) =>
    themeColor === Themes.white ? "0px 84px" : "60px 84px"};
  background: ${({ theme, themeColor }) =>
    themeColor === Themes.white ? theme.colors.white : theme.colors.grey};
  max-width: 833px;

  @media only screen and (max-width: 800px) {
    padding: 15px 40px;
  }
`;

const VideoResponsive = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  max-width: 665px;
  position: relative;
  height: 0;
`;

export const StyledIframe = styled.iframe`
  border-radius: 10px;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
`;

const VideoPanelWrapper = styled.section`
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
`;

export default VideoPanelDesktopComponent;
