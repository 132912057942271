export const Themes = {
  darkGreen: "Dark Green",
  lightGreen: "Light Green",
  darkGrey: "Dark Grey",
  lightGrey: "Light Grey",
  image: "Image",
  white: "White",
  blue: "Blue",
  green: "Green",
  greenLight: "Light green",
  h2Cta: "H2",
  navItem: "navigation_item",
  navButtonItem: "navigation_button",
  blueGreen: "Blue Green",
};
