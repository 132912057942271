import React from "react";
import styled from "styled-components";
import { LustriaP } from "../../text";

export const AccordionDesktopClosed = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 24px;
  padding: 20px 12px 21px 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 656px;

  @media only screen and (max-width: 800px) {
    width: 318px;
  }
`;
export const FaqTitle = styled(LustriaP)`
  max-width: 592px;
  font-size: 25px;
  font-weight: 400;
  line-height: 32px;

  @media only screen and (max-width: 800px) {
    max-width: 254px;
  }
`;
export const ButtonIcon = styled.img`
  width: 48px;
  height: 48px;
  cursor: pointer;
`;

const AccordionDesktopClosedComponent = ({ className, question, open }) => {
  return (
    <AccordionDesktopClosed className={className}>
      <FaqTitle>{question}</FaqTitle>
      <ButtonIcon
        onClick={open}
        alt="buttonIcon"
        src="https://static.overlay-tech.com/assets/093b4b34-ff9f-4df8-a150-30f635ad9663.svg"
      />
    </AccordionDesktopClosed>
  );
};

export default AccordionDesktopClosedComponent;
