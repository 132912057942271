import React from "react";
import styled from "styled-components";
import RelatedPageCard from "./components/RelatedPage";
import { RichText } from "prismic-reactjs";

const contentTitle = "Discover more about Skildare";

const handleDestructure = ({ primary, fields }) => {
  const title = primary.title && primary.title.length > 0 ? primary.title[0].text || contentTitle : contentTitle;

  const result = {
    title: title,
    pages: []
  };
  
  if (!fields) return null;
  fields.forEach((page) => {
    const relatedPage = page?.related_page;
    result.pages.push({
      title: RichText.asText(relatedPage?.seo[0]?.seo_title),
      description: RichText.asText(relatedPage?.seo[0]?.seo_description),
      image: relatedPage?.seo[0]?.seo_image?.url,
      url: relatedPage?._meta?.uid.replace(/__/g, "/"),
      type: relatedPage?.__typename,
    });
  });

  return result;
};

const RelatedPages = (sliceObject) => {
  
  const content = handleDestructure(sliceObject);

  if (!content) return null;
  
  return (
    <RelatedPagesWrapper aria-label={content.title}>
      <HorizontalLine />
      <RelatedPagesText>{content.title}</RelatedPagesText>
      <div class="row d-flex pb-5">
        {content.pages.map((related_page) => {
          return (
            <PageWrapper className="col-md-6">
              <RelatedPageCard {...related_page} />
            </PageWrapper>
          );
        })}
      </div>{" "}
    </RelatedPagesWrapper>
  );
};

const RelatedPagesText = styled.h3`
  font-family: Lustria;
  font-size: 25px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.steelBlue};
`;
const HorizontalLine = styled.hr`
  height: 0.5px;
  color: ${({ theme }) => theme.colors.steelBlue};
  margin-bottom: 32px;
`;
const PageWrapper = styled.div`
  margin-top: 32px;
  flex: 0 1 auto;
  height: 397px;
`;
const RelatedPagesWrapper = styled.section`
  max-width: 796px;
  margin: 0 auto;
  @media only screen and (max-width: 1200px) {
    padding: 0 24px;
  }
`;

export default RelatedPages;
