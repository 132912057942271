import { RichText } from "prismic-reactjs";
import React from "react";
import styled from "styled-components";
import { htmlSerializer } from "../../templates/ContentRender";

const handleDataManipulation = (primary) => {
  return [{ content: primary.text }];
};

const renderRichTextContent = (content, isCaseStudy = false) => {
  if (!content) return null;
  return content.map((x) => (
    <RichText render={x.content} htmlSerializer={htmlSerializer(isCaseStudy)} />
  ));
};

const RichTextComponent = ({ primary, isCaseStudy }) => {
  const content = handleDataManipulation(primary);

  if (!content) return null;

  return (
    <ContentWrapper>
      <>{renderRichTextContent(content, isCaseStudy)} </>
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  max-width: 670px;
  margin: 0 auto;
  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  @media (max-width: 1200px) {
    padding: 0 24px 48px;
  }

  p {
    font-family: OpenSans;
    color: ${({ theme }) => theme.textColors.greySecondary};
  }
`;
export default RichTextComponent;
