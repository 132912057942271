import React from "react";
import styled from "styled-components";
import { routeHelper } from "../../../utils/routeHelper";
import { Themes } from "../../models/themes";
import { OpenSansP } from "../../text";

const CTAButton = ({ title, href, theme }) => {
  return (
    <ButtonWrapper
      href={`${routeHelper(href)}`}
      colorMode={theme}
      className="d-flex flex-row align-items-center justify-content-center"
    >
      {title}
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.a`
  transition: all 0.3s;
  margin: 0 auto;
  max-width: 220px;
  white-space: nowrap;
  border-radius: 4px;
  padding: 10px 15px 11px 11px;
  margin-top: 48px;
  @media only screen and (max-width: 1200px) {
    margin-top: 24px;
  }
  text-decoration: none;
  font-size: 18px;
  font-family: Raleway-Semibold;
  font-weight: 600;
  line-height: 24px;
  border: 1px solid
    ${({ theme, colorMode }) => {
      switch (colorMode) {
        case Themes.blueGreen:
        case Themes.darkGreen:
        case Themes.darkGrey:
          return theme.colors.blueGreen;
        case Themes.lightGrey:
        case Themes.image:
          return theme.colors.grey;
        case null:
          return "transparent";
        default:
          return theme.colors.mainBlue;
      }
    }};

  &:not(:last-of-type) {
    margin-right: 24px;
    @media only screen and (max-width: 1200px) {
      &:not(:last-of-type) {
        margin-right: 16px;
      }
    }
  }

  color: ${({ colorMode, theme }) => {
    switch (colorMode) {
      case Themes.lightGreen:
      case Themes.lightGrey:
      case Themes.image:
      case Themes.white:
        return "white";      
      case Themes.green:
        return theme.colors.navyPrimary;
      case Themes.darkGrey:
      case Themes.darkGreen:
        return theme.colors.blueGreen;
      case Themes.blueGreen:
        return theme.colors.white;
      case null:
      default:
        return theme.textColors.steelBlue;
    }
  }};
  background-color: ${({ colorMode, theme }) => {
    switch (colorMode) {
      case Themes.darkGreen:
      case Themes.darkGrey:
      case Themes.green:
        return "white";
      case Themes.lightGreen:
      case Themes.white:
        return theme.colors.navyPrimary;
      case Themes.blueGreen:
        return theme.colors.blueGreen;
      case Themes.lightGrey:
      case Themes.image:
        return theme.colors.grey;
      default:
        return "transparent";
    }
  }};
  :hover {
    color: ${({ colorMode, theme }) => {
      switch (colorMode) {
        case Themes.lightGreen:
        case Themes.lightGrey:
        case Themes.image:
        case Themes.white:
          return "white";
        case Themes.green:
          return theme.colors.navyPrimary;
        case Themes.darkGrey:
        case Themes.darkGreen:
          return theme.colors.steelBlue;
        case Themes.blueGreen:
          return theme.colors.white;
        case null:
        default:
          return theme.textColors.steelBlue;
      }
    }};
    background-color: ${({ colorMode, theme }) => {
      switch (colorMode) {
        case Themes.green:
        case Themes.blueGreen:
          return theme.colors.grey;
        case Themes.darkGreen:        
        case Themes.lightGreen:
        case Themes.white:
        case Themes.darkGrey:
          return theme.colors.lightButtonHover;
        case Themes.lightGrey:
        case Themes.image:
          return theme.colors.steelBlue;
        default:
          return "";
      }
    }};

    text-decoration: ${({ colorMode }) => (colorMode ? "none" : "underline")};
    text-decoration-color: ${({ theme }) => theme.colors.steelBlue};

    border: 1px solid
      ${({ colorMode, theme }) => {
        switch (colorMode) {
          case Themes.darkGreen:        
          case Themes.darkGrey:
            return theme.colors.steelBlue;
          case Themes.blueGreen:
            return theme.colors.grey;
          default:
            return "transparent";
        }
      }};
  }

  :focus {
    border: ${({ colorMode, theme }) => {
      switch (colorMode) {
        case Themes.darkGreen:        
        case Themes.blueGreen:
          return "1px solid " + theme.colors.blueGreen;
        case Themes.darkGrey:
          return "1px solid " + theme.colors.blueGreen;
        default:
          return "";
      }
    }};
  }

  :active {
    background-color: ${({ colorMode, theme }) => {
      switch (colorMode) {
        case Themes.darkGreen:
        case Themes.darkGrey:
          return theme.colors.steelBlueOpacity_01;
        case Themes.lightGreen:
        case Themes.white:
          return theme.colors.navyBackgroundPressed;
        case Themes.blueGreen:
          return theme.colors.darkBlue;
        case Themes.green:
          return theme.colors.lightButtonPressed;
        case Themes.image:
          return theme.colors.grey;
        default:
          return "";
      }
    }};
  }
`;

const ButtonText = styled(OpenSansP)`
  text-align: center;
  font-size: 18px;
  font-family: Raleway-Semibold;
  width: 100%;
  color: ${({ colorMode, theme }) => {
    switch (colorMode) {
      case Themes.lightGreen:
      case Themes.lightGrey:
      case Themes.image:
      case Themes.white:
        return "white";

      case Themes.green:
        return theme.colors.navyPrimary;
      case Themes.darkGreen:
      case Themes.darkGrey:
        return theme.colors.blueGreen;
      case Themes.blueGreen:
        return theme.colors.white;
      case null:
      default:
        return theme.textColors.steelBlue;
    }
  }};
  :active {
    color: white;
  }
  :hover {
  }
`;

export default CTAButton;
