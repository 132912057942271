import React from "react";
import styled from "styled-components";
import RightIcon from "../../components/icons/RightIcon";
import { handleCTALink } from "../../components/layouts/components/CallToActionH2";
import { routeHelper } from "../../utils/routeHelper";
import { RichText } from "prismic-reactjs";

const handleCTADestructure = (cta) => {
  const defaultAltText = "image";

  if (cta?.cta) {
    return {
      image: cta?.cta?.call_to_action_image?.url,
      title: RichText.asText(cta?.cta?.call_to_action_title),
      subtitle: RichText.asText(cta?.cta?.all_to_action_text),
      link: cta?.cta?.call_to_action_link?._meta?.uid
        ? cta?.cta?.call_to_action_link?._meta?.uid.replace(/__/g, "/")
        : cta?.cta?.call_to_action_link?.url,
      altText: cta?.cta?.call_to_action_image?.alt ?? defaultAltText
    };
  } else if (cta?.primary) {
    return {
      image: cta?.primary.image?.url,
      title: RichText.asText(cta?.primary?.title1),
      subtitle: RichText.asText(cta?.primary?.subtitle),
      link: cta?.primary.link?._meta?.uid
        ? cta?.primary.link?._meta?.uid.replace(/__/g, "/")
        : cta?.primary.link?.url,
      altText: cta?.primary.image?.alt ?? defaultAltText      
    };
  } else return null;
};

const CallToAction = (primary) => {
  const cta = handleCTADestructure({ ...primary });

  if (!cta) return null;
  return (
    <CallToActionLink href={`${routeHelper(handleCTALink(cta.link))}`}>
      <CallToActionWrapper className="d-flex justify-content-center flex-row">
        <ContentWrapper className="d-flex align-items-center">
          <CTAImage src={cta.image} width="141px" height="100%" alt={cta.altText} />
          <div className="ms-3">
            <CTASubtitle>{cta.title}</CTASubtitle>
            <CTATitle>{cta.subtitle}</CTATitle>
            <RightIcon />
          </div>
        </ContentWrapper>
      </CallToActionWrapper>
    </CallToActionLink>
  );
};

const ContentWrapper = styled.div`
  @media only screen and (max-width: 1200px) {
    flex-direction: row-reverse;
  }
`;

export const CallToActionWrapper = styled.div`
  padding: 27px 0;
  background-color: ${({ theme }) => theme.colors.steelBlueOpacity_01};
  font-family: OpenSans-Bold;
  font-size: 31px;
  color: ${({ theme }) => theme.colors.steelBlue};
  cursor: pointer;

  div {
    max-width: 1000px;
  }
`;

const CallToActionLink = styled.a`
  text-decoration: none;
`;

const CTAImage = styled.img`
  max-height: 160px;
  width: auto;
  height: auto;

  @media only screen and (max-width: 1200px) {
    padding-left: 24px;
  }
`;

const CTATitle = styled.h2`
  font-family: Lustria;
  padding-bottom: 8px;
  color: ${({ theme }) => theme.textColors.grey};
  @media only screen and (max-width: 800px) {
    font-size: 18px;
  }
`;

const CTASubtitle = styled.h2`
  opacity: 0.6;
  padding-bottom: 8px;

  @media only screen and (max-width: 800px) {
    font-size: 16px;
  }
`;

export default CallToAction;
