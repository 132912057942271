import React from "react";
import styled from "styled-components";
import { Themes } from "../models/themes";
import SkildareLogo from "../icons/SkildareLogo";
import { RichText } from "prismic-reactjs";

const handleQuoteDestructure = ({ primary }) => {
  return {
    quote: RichText.asText(primary?.quote),
    author: RichText.asText(primary?.author),
    color: primary?.background_color,
  };
};

const PullQuoteComponent = (primary) => {
  const quote = handleQuoteDestructure({ ...primary });

  if (!quote) return null;

  return (
    <PullQuoteWrapper
      isCaseStudy={primary.isCaseStudy}
      className="d-flex justify-content-center "
      colorMode={quote.color}
    >
      <ContentWrapper>
        <TextWrapper>
          <QuoteText>"{quote.quote}"</QuoteText>
          <AuthorText>{quote.author}</AuthorText>
        </TextWrapper>
        {!primary.isCaseStudy && (
          <IconWrapper>
            <SkildareLogo></SkildareLogo>
          </IconWrapper>
        )}
      </ContentWrapper>
    </PullQuoteWrapper>
  );
};

const TextWrapper = styled.div`
  padding: 0 24px;
`;

const ContentWrapper = styled.div``;

const IconWrapper = styled.div`
  position: absolute;
  @media only screen and (max-width: 1200px) {
    //-38px is due to width and height of SVG icons and their position
    margin: 24px 0 0 -38px;
    left: 50%;
  }
`;

const PullQuoteWrapper = styled.div`
  max-width: ${({ isCaseStudy }) => (isCaseStudy ? "712px" : "auto")};
  margin: ${({ isCaseStudy }) => (isCaseStudy ? "0 auto" : "auto")};
  text-decoration: none;
  padding: ${({ colorMode, theme }) =>
      colorMode === Themes.white ? "0px" : "96px"}
    0
    ${({ colorMode, theme }) => (colorMode === Themes.white ? "96px" : "128px")};
  background-color: ${({ colorMode, theme }) => {
    switch (colorMode) {
      case Themes.white:
        return theme.textColors.white;
      case Themes.lightGreen:
        return theme.colors.steelBlueOpacity_01;
      default:
        return theme.textColors.grey;
    }
  }};
  color: ${({ theme, colorMode }) => {
    switch (colorMode) {
      case Themes.lightGreen:
        return theme.textColors.steelBlue;
      default:
        return theme.textColors.grey;
    }
  }};
  cursor: default;
  ${({ theme, colorMode }) =>
    colorMode === Themes.image
      ? `box-shadow: inset 0 0 0 1000px ${theme.colors.navyTransparent};`
      : ""}
  div {
    max-width: 950px;
  }
`;

const QuoteText = styled.h2`
  font-family: Lustria;
  font-size: 39px;
  line-height: 48px;
  padding-bottom: 24px;
  text-align: center;
  @media only screen and (max-width: 800px) {
    font-size: 31px;
    line-height: 36px;
  }
`;

const AuthorText = styled(QuoteText)`
  font-family: Open Sans;
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 0;
  color: ${({ theme }) => theme.textColors.greySecondary};
`;

export default PullQuoteComponent;
