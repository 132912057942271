import React from "react";
import styled from "styled-components";
import CTAButton from "../layouts/components/CTAButton";
import { Themes } from "../models/themes";
import { routeHelper } from "../../utils/routeHelper";
import { OpenSansP } from "../text";
import { RichText } from "prismic-reactjs";

const handleHeroLink = (item) => {
  if (item?.includes(".")) {
    return item;
  }
  if (item?.includes("https://")) return item.split("https://")[1];
  return `/${item}`;
};

const handleDestructure = ({ primary }) => {
  const title = RichText.asText(primary?.title);
  const description = RichText.asText(primary?.description);
  const imageUrl = primary?.image?.url;
  const displayCta = primary?.display_cta;
  const ctaPrimaryLabel = RichText.asText(primary?.cta_primary_label);
  const ctaSecondaryLabel = RichText.asText(primary?.cta_secondary_label);
  const ctaPrimary = primary?.cta_primary?._meta?.uid
    ? primary?.cta_primary?._meta?.uid.replace(/__/g, "/")
    : primary?.cta_primary?.url;
  const ctaSecondary = primary?.cta_secondary?._meta?.uid
    ? primary?.cta_secondary?._meta?.uid.replace(/__/g, "/")
    : primary?.cta_secondary?.url;

  return {
    title,
    description,
    displayCta,
    imageUrl,
    ctaPrimaryLabel,
    ctaPrimary,
    ctaSecondaryLabel,
    ctaSecondary,
  };
};

export default (slice) => {
  const landingPage = handleDestructure(slice);

  if (!landingPage) return null;
  const contentTitle = landingPage.title;
  return (
    <LandingPageWrapper aria-label={contentTitle}>
      <LeftSide>
        <ContentWrapper>
          <div>
            <Title>{contentTitle}</Title>
            <Description>{landingPage.description}</Description>
            {landingPage.displayCta && (
              <ActionsWrapper>
                <CTAButton
                  title={landingPage.ctaPrimaryLabel}
                  href={`${routeHelper(
                    handleHeroLink(landingPage.ctaPrimary)
                  )}`}
                  theme={Themes.blueGreen}
                ></CTAButton>
                <CTAButton
                  title={landingPage.ctaSecondaryLabel}
                  href={`${routeHelper(
                    handleHeroLink(landingPage.ctaSecondary)
                  )}`}
                  theme={null}
                ></CTAButton>
              </ActionsWrapper>
            )}
          </div>
        </ContentWrapper>
      </LeftSide>
      <RightSide backgroundImage={landingPage.imageUrl}></RightSide>
    </LandingPageWrapper>
  );
};

const LandingPageWrapper = styled.section`
  display: flex;
  justify-content: flex-start;
  height: 900px;
  position: relative;
`;

const Side = styled.div`
  width: 50%;
  height: 100%;
`;

const LeftSide = styled(Side)`
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 800px) {
    width: 0%;
  }
`;

const RightSide = styled(Side)`
  background-image: url(${({ backgroundImage }) => {
    return backgroundImage;
  }});
  background-repeat: no-repeat;

  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.whiteOpacity_02};
  padding: 30px 150px 50px;
  width: 800px;
  z-index: 1;
  margin-right: -250px;

  @media only screen and (max-width: 800px) {
    position: absolute;
    width: auto;
    margin: 0;
    padding: 15px 30px 30px;
    left: 90px;
    right: 0;
  }
`;

const Title = styled.h1`
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0px;
  text-align: left;
  font-family: Lustria;
  color: ${({ theme }) => theme.textColors.grey};
  max-width: 330px;
`;

const Description = styled(OpenSansP)`
  margin-top: 20px;
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme }) => theme.textColors.steelBlue};
  max-width: 460px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  flex-start;
  margin-top: 40px;
  a {
    margin: 0 !important;
  }

  @media only screen and (max-width: 800px) {
    flex-wrap: wrap;
  }
`;
