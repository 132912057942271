import React from "react";
import styled from "styled-components";
import RightArrowIcon from "../../icons/RightArrowIcon";
import { routeHelper } from "../../../utils/routeHelper";
import { pageTypes } from "../../models/prismicTypes";
import {
  LinkWrapper,
  Wrapper,
  ContentWrapper,
  Content,
  ImageWrapper,
} from "../../case-study/CaseStudy";
import { LustriaH2, OpenSansP } from "../../text";

const RelatedPageCard = (related_page) => {
  return (
    <LinkWrapper
      href={`${routeHelper(
        related_page.type === pageTypes.caseStudy
          ? `/case-studies/${related_page?.url}`
          : `/${related_page?.url}`
      )}`}
    >
      <PageWrapper>
        <ContentWrapper>
          <PaddedContent>
            <div className="d-flex flex-row align-items-center justify-content-between">
              <StyledTitle>{related_page.title}</StyledTitle>
              <RightArrowIcon />
            </div>
            <PageContent>{related_page.description}</PageContent>
          </PaddedContent>
        </ContentWrapper>
        <ImageWrapper>
          <img
            src={related_page.image}
            width="100%"
            height="100%"
            alt={`Related page - ${related_page.title}`}
          />
        </ImageWrapper>
      </PageWrapper>
    </LinkWrapper>
  );
};

const StyledTitle = styled(LustriaH2)`
  font-size: 20px;
  line-height: 28px;
`;

const PageWrapper = styled(Wrapper)`
  max-width: 374px;
  margin-left: auto;
  margin-right: auto;
`;

const PageContent = styled(OpenSansP)`
  margin-top: 8px;
  font-size: 17px;
  line-height: 28px;
  opacity: 0.6;
`;

const PaddedContent = styled(Content)`
  padding: 24px !important;
`;

export default RelatedPageCard;
