import React, { useEffect, useState } from "react";
import styled from "styled-components";

export const BulletPoint = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
`;
export const IconSuccessItem = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
  &:after {
    content: "";
    position: absolute;
    height: 30px;
    width: 2px;
    background-color: ${({ theme }) => theme.textColors.steelBlue};
    transform: rotate(45deg);
    right: 10px;
    top: -4px;
  }
  margin-right: 12px;
  margin-top: 6px;
`;
export const BulletText = styled.p`
  width: 474px;
  font-family: ${(props) =>
    props.theme.fonts.desktop4HeadingMBlackDefaultLeft.family};
  font-size: 20px;
  font-weight: ${(props) =>
    props.theme.fonts.desktop4HeadingMBlackDefaultLeft.weight};
  line-height: 28px;
  color: ${({ theme }) => theme.textColors.grey};

  @media only screen and (max-width: 800px) {
    width: 300px;
  }
`;

export const BulletLink = styled.a`
  width: 474px;
  font-family: ${(props) =>
    props.theme.fonts.desktop4HeadingMBlackDefaultLeft.family};
  font-size: 20px;
  font-weight: ${(props) =>
    props.theme.fonts.desktop4HeadingMBlackDefaultLeft.weight};
  line-height: 28px;
  color: ${({ theme }) => theme.textColors.grey};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.textColors.grey};
  }

  @media only screen and (max-width: 800px) {
    width: 300px;
  }
`;

const checkIsPhoneNumber = (number) => /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/.test(number);

const checkIsEmail = (email) => /[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,6}/.test(email);

const checkIsUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}

const getMapsUrlText = (url) => {
  const endString = url.split('query=')[1];
  return endString.replaceAll('%20', ' ');
}

const mapLink = (type, link) => {
  switch (type) {
    case 'phone':
      return `tel: ${link}`;
    case 'email':
      return `mailto: ${link}`;
    default:
      return link;
  };
};

const mapLinkContent = (type, content) => {
  switch (type) {
    case 'url':
      return getMapsUrlText(content);
    default:
      return content;
  }
}

const BulletPointComponent = ({ className, children, content }) => {
  const [linkType, setLinkType] = useState('');
  useEffect(() => {
    if (checkIsPhoneNumber(content)) {
      setLinkType('phone');
    }
    else if (checkIsEmail(content)) {
      setLinkType('email');
    }
    else if (checkIsUrl(content)) {
      setLinkType('url');
    }
  }, []);
  return (
    <BulletPoint className={className}>
      <IconSuccessItem />
      {!linkType && <BulletText>{content}</BulletText>}
      {linkType && <BulletLink href={mapLink(linkType, content)}>{mapLinkContent(linkType, content)}</BulletLink>}
    </BulletPoint>
  );
};

export default BulletPointComponent;
